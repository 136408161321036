/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import '@ionic/angular/css/ionic-swiper';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/theming';
@include mat-core();

@import "sweetalert2/src/sweetalert2.scss";

/*
Fonts
 */
@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsB';
  src: url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsBolder';
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.poppinsBolder {
  font-family: 'PoppinsBolder';
}

.poppinsB {
  font-family: 'PoppinsB';
}

.poppins {
  font-family: 'Poppins';
}

.ion-item-form {
  --background: rgb(245 245 245 / 80%);
  --border-radius: 4px;
}

$matgold: (
  50 : #4C5759,
  100 : #4C5759,
  200 : #4C5759,
  300 : #4C5759,
  400 : #4C5759,
  500 : #4C5759,
  600 : #4C5759,
  700 : #4C5759,
  800 : #4C5759,
  900 : #4C5759,
  A100 : #ffffff,
  A200 : #fffefc,
  A400 : #ffeec9,
  A700 : #ffe7af,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my-app-primary: mat-palette($matgold, 500);
$my-app-accent: mat-palette($mat-pink, 500, 900, A100);
$my-app-warn: mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

.alternate-theme {
  $alternate-primary: mat-palette($mat-light-blue);
  $alternate-accent: mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
}

@keyframes mouvement-haut-bas {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.div-animation {
  animation: mouvement-haut-bas 1s ease-in-out infinite;
}

.table-bordered {
  thead {
    background: var(--ion-color-tn-black) !important;
    color: white !important;
    font-size: 13px !important;
  }

  tbody {
    font-size: 13px !important;
    font-weight: font-bold !important;
  }
}

.event-detail-container {
  border-top: 10px var(--ion-color-tn-black) solid !important;
  background: transparent !important;

  ion-item {
    border: 1px solid var(--ion-color-tn-black);
    border-radius: 10px;
    margin: 5px;
  }
}

// .calendar-event-inner {
//   background-color: #3aa5da !important;
// }

// .weekview-allday-table {
//   display: none !important;
// }

// .dayview-allday-table {
//   display: none !important;
// }

weekview-normal-event-container {
  margin-top: -10px !important;
}

ion-title {
  font-weight: font-bold;
}

ion-card {
  margin: 0px;
}

.time-modal {
  --height: 300px !important;
  --width: 90%;
  --backdrop-opacity: 0.5;
  --border-radius: 15px;

  ion-datetime {
    height: 100%;
    width: 100%;
    background-color: white !important;
    --background: white;

    div {
      color: var(--ion-color-primary);
    }
  }
}

ion-item-divider {
  --background: var(--ion-color-primary);
  min-height: 5px !important;
  margin-top: 10px;
}

* {
  outline: none !important;
  font-family: 'Poppins';
}

.tn-black {
  color: var(--ion-color-tn-black);
  --color: var(--ion-color-tn-black);
}

.white {
  color: white;
  --color: white;
}

.bg-gold {
  background: #e3cb80;
  background-color: #e3cb80;
  --background: #e3cb80;
}

.bg-tn-beige {
  background: var(--ion-color-tn-beige);
  --background: var(--ion-color-tn-beige);
}

.bg-tn-black {
  background: var(--ion-color-tn-black);
  --background: var(--ion-color-tn-black);
}

.bg-rose {
  background: var(--ion-color-tn-rose);
  --background: var(--ion-color-tn-rose);
}

.bg-orange {
  background: var(--ion-color-tn-orange);
  --background: var(--ion-color-tn-orange);
}

.bg-blue {
  background: var(--ion-color-tn-blue);
  --background: var(--ion-color-tn-blue);
}

.bg-trans {
  background: transparent;
  --background: transparent;
}

.bg-beige {
  --background: #f9f5f0;
  background: #f9f5f0;
}

// Time modal
.time-modal {
  --height: 250px !important;
  --width: 400px;
  --backdrop-opacity: 0.5;
  --border-radius: 15px;

  ion-datetime {
    height: 100%;
    width: 100%;
    background-color: white;

    div {
      color: var(--ion-color-primary);
    }
  }
}

.underline-hover:hover {
  text-decoration: underline;
}

.z--1 {
  z-index: -1;
}

.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.border-radius-half {
  border-radius: 50%;
  --border-radius: 50%;
}


.mat-table-responsive {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 576px) {
    display: block;
    * {
      font-size: 10px;
      text-align: center;
    }
  }
}

.newsletter {
  --width: 500px;
  --height: 500px;
}